import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer'
import SideMenu from '../dashboard_components/SideMenu'
import FilterForm from '../dashboard_components/FilterForm'
import BarChartHolder from '../dashboard_components/BarChartHolder'
import MapHolder from '../dashboard_components/MapHolder'
import LineChartHolder from '../dashboard_components/LineChartHolder'
import BlockPerformance from '../dashboard_components/BlockPerformance.jsx'
import PieChartHolder from '../dashboard_components/PieChartHolder'
import TableHolder from '../dashboard_components/TableHolder'
import DataQualityHolder from '../dashboard_components/DataQualityHolder'
import { SkeletonQuadrant, SkeletonDateDiv, SkeletonCompare } from "./SkeletonCard";
import { fetchData } from '../util.js'
import useAxios from './useAxios';
import $ from 'jquery';
import ReactSlider from 'react-slider';
import '../cssMinifiedFiles/tailwind.min.css';
import Popup from "../dashboard_components/Popup/Popup";
import TableChartIcon from '@material-ui/icons/TableChart';
import '../dashboard_components/chart.css'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthContext from '../context/AuthContext';
import moment from 'moment';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';

export default function Dashboard() {

    const [barChartData, setbarChartData] = useState([]);
    const [indicator, setIndicator] = useState(23);
    const [fyear, setFyear] = useState(3);
    const [month, setMonth] = useState(13);
    const [district, setDistrict] = useState(22);
    const [sub_district, setSubDt] = useState(3652);
    const [area, setArea] = useState(22);
    const [areaType, setAreaType] = useState('District');
    const [indicatorType, setIndicatorType] = useState('negative');
    const [isSelected, setIsSelected] = useState(false);
    const [indicatorCategory, setIndicatorCategory] = useState(1);
    const [areaDetails, setAreaDetails] = useState([]);
    const [fyDropdown, setFYDropdown] = useState([]);
    const [monthDropdown, setMonthDropdown] = useState([]);
    const [indicatorDropdown, setIndicatorDropdown] = useState([]);
    const [districtDropdown, setDistrictDropdown] = useState([]);
    const [subdtDropdown, setSubDtDropdown] = useState([]);
    const location = useLocation();
    const [lineChartData, setLineChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [qualityData, setQualityData] = useState([]);
    const [indicatorVariant, setIndicatorVariant] = useState('Both');
    const [min, setMin] = useState(25);
    const [max, setMax] = useState(50);
    const [isOpenTable, setIsOpenTable] = useState(false);
    const [isColorCodedTable, setIsColorCodedTable] = useState(false);
    const [toggleValue, setToggleValue] = useState('percent');
    const [DataDownloadedDate, setDataDownloadedDate] = useState('2020-11-09');
    const [bestWorstPerformingBlock, setBestWorstPerformingBlock] = useState([]);
    let { user, logoutUser } = useContext(AuthContext);
    let dtAccessID = user.areaIDs;
    const api = useAxios();
    let pdfPath;

    useEffect(() => {

        async function getInitialData() {

            const [response1, response2, response3, response4] = await Promise.all([
                api.get(`/area/${dtAccessID}`),
                api.get('/fy'),
                api.get('/month'),
                api.get('/indicators')
            ]);

            if (response1.status === 200) {

                let areaData = response1.data.filter(f => (f.area_level != 5));
                setAreaDetails(areaData);
                let districtData = areaData.filter(f => (f.area_type === 'District'));
                let subDtData = areaData.filter(f => (f.area_type === 'SubDistrict'));
                setDistrictDropdown(districtData);
                setSubDtDropdown(subDtData);

                let fyData, indicatorData;
                if (['5', '6'].includes(user.id)) {
                    fyData = response2.data.filter(m => (m.fy_id < 5));
                    indicatorData = response4.data.filter(i => i.indicator_id < 46);
                    pdfPath = '/assets/document/MH-HMIS_Analyzer_Dashboard_Manual_36indicators.pdf';
                }
                else {
                    fyData = response2.data;
                    indicatorData = response4.data;
                    pdfPath = '/assets/document/MH-HMIS_Analyzer_Dashboard_Manual_28-02-2024.pdf';
                }

                setFYDropdown(fyData);
                setIndicatorDropdown(indicatorData);

                let monthData = response3.data.filter(m => (m.month_id < 14));
                setMonthDropdown(monthData);

            } else if (response1.statusText === 'Unauthorized') {
                logoutUser()
            }
        }
        getInitialData();

    }, []);

    useEffect(() => {
        setIsSelected(false);

        async function getNewIndicatorData() {

            const indiData = await api.get('/indicators');
            let indicatorList = indiData.data;
            let url = location.pathname;
            let newUrl = url.split('/');
            let indicator_label = newUrl[newUrl.length - 1];
            let indicatorId = 23, indicator_cat = 1;
            if (indicator_label != 'dashboard' && indicator_label != undefined && indicator_label != "") {
                let indicatorObject = indicatorList.filter(f => f.name === indicator_label)[0]
                indicatorId = indicatorObject.indicator_id;
                let indicator_type = indicatorObject.type;
                if (indicator_type === "negative") {
                    $('.example-track-0').css("background", "#30A054");
                    $('.example-track-2').css("background", "#ff0000");
                }
                else {
                    $('.example-track-0').css("background", "#ff0000");
                    $('.example-track-2').css("background", "#30A054");
                }
                indicator_cat = indicatorObject.category;
                let indicator_variant = indicatorObject.variant;

                if (indicator_variant === 'Numeric')
                    setToggleValue('count')
                else
                    setToggleValue('percent');

                setIndicator(indicatorId);
                setIndicatorType(indicator_type);
                setIndicatorCategory(indicator_cat);
                setIndicatorVariant(indicator_variant);
            } else {
                indicatorId = 23;
                let indicator_type = 'negative';
                let indicator_cat = 1;
                let indicator_variant = 'Both';
                setIndicator(indicatorId);
                setIndicatorType(indicator_type);
                setIndicatorCategory(indicator_cat);
                setIndicatorVariant(indicator_variant);
            }
            await fetchData(fyear, month, area, indicatorId, areaType, indicator_cat, setbarChartData, setLineChartData, setTableData, setQualityData, setDataDownloadedDate, setBestWorstPerformingBlock, api, logoutUser);
            setIsSelected(true);
        }
        getNewIndicatorData();

    }, [location]);

    const displayValues = (e) => {
        setMin(e[0]);
        setMax(e[1]);
    };

    const toggleTablePopup = () => {
        setIsOpenTable(!isOpenTable);
    };

    const toggleColorTablePopup = () => {
        setIsColorCodedTable(!isColorCodedTable);
    }

    const ToggleButtonChange = (event, newAlignment) => {
        setToggleValue(newAlignment);
    };


    const theme = createTheme({
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        border: `1px solid #000`,
                        color: '#000',
                        fontWeight: 'bold'
                    },
                },
            },
        },
    });

    useEffect(() => {
        if ([9, 10, 42, 45].includes(indicator)) {
            $("#toggleButtonLabel").text('Rate');
        } else
            $("#toggleButtonLabel").text('Percent');
    }, [indicator]);

    //Disabling Toggle Button for only Percent and only Numeric parameters
    let disablingToggleButton = false;
    if (indicatorVariant === 'Percent' || indicatorVariant === 'Numeric')
        disablingToggleButton = true;

    let ToggleButtonSwitch = <ThemeProvider theme={theme}>
        <ToggleButtonGroup
            color="info"
            value={toggleValue}
            fullWidth={true}
            exclusive
            onChange={ToggleButtonChange}
            aria-label="Platform"
            variant="contained"
            disabled={disablingToggleButton}
        >
            <ToggleButton value="percent"><span id="toggleButtonLabel">Percent</span></ToggleButton>
            <ToggleButton value="count">Count</ToggleButton>
        </ToggleButtonGroup>
    </ThemeProvider>

    //Disabling Slider for numeric data elements    
    let isDisabled = false;
    if (toggleValue === 'count') {
        isDisabled = true;
    }

    if (['5', '6'].includes(user.id)) {
        pdfPath = '/assets/document/MH-HMIS_Analyzer_Dashboard_Manual_36indicators.pdf';
    } else {
        pdfPath = '/assets/document/MH-HMIS_Analyzer_Dashboard_Manual_28-02-2024.pdf';
    }

    const downloadAll = async () => {
        // const pageTitle = await htmlToImage.toPng(document.getElementById('goal-title'), { quality: 1, width: 600, height: 150 });
        const mapURL = await htmlToImage.toPng(document.getElementById('svgMap'), { quality: 1 });
        const lineChartURL = await htmlToImage.toPng(document.getElementById('lineChart'), { quality: 1 });
        const barChartURL = await htmlToImage.toPng(document.getElementById('barChart'), { quality: 1 });
        const pieChartURL = await htmlToImage.toPng(document.getElementById('pieChart'), { quality: 1 });
        const blockTableURL = await htmlToImage.toPng(document.getElementById('block-table'), { quality: 1 });
        const imageURLs = [mapURL, lineChartURL, barChartURL, pieChartURL, blockTableURL];

        const pdf = new jsPDF("l");
        let width = pdf.internal.pageSize.getWidth();
        let height = pdf.internal.pageSize.getHeight();


        pdf.setFontSize(30);
        pdf.setFont(undefined, "bold");
        pdf.text(`Maharashtra HMIS - Data Analysis and Visualization`, (width / 2) / 7, height / 8);
        pdf.setFontSize(15)
        pdf.text(`(Dashboard Page)`, width / 2, height / 6, { align: 'center' });

        pdf.addImage('/assets/img/pregnancy.png', 'PNG', 30, height / 3, 50, 50);
        pdf.addImage('/assets/img/deliveries.png', 'PNG', 95, height / 3, 50, 50);
        pdf.addImage('/assets/img/child_immunisation.png', 'PNG', 160, height / 3, 50, 50);
        pdf.addImage('/assets/img/child_disease.png', 'PNG', 225, height / 3, 50, 50);

        pdf.setFontSize(10);
        pdf.text(`Developed in Collaboration with`, width / 1.8, height - 20);
        pdf.addImage('/assets/img/nhm_mh_logo.png', 'PNG', 230, height - 25, 10, 10);
        pdf.addImage('/assets/img/iitb.png', 'PNG', 245, height - 25, 10, 10);
        pdf.addImage('/assets/img/ctara.png', 'PNG', 260, height - 23, 20, 7);
        pdf.addPage();

        imageURLs.forEach((url, index) => {
            let link = document.createElement('a');
            link.download = 'imageNameJpeg';

            let img = new Image();
            img.src = url;
            img.onload = function () {

                if (index !== (imageURLs.length - 1)) {
                    pdf.addImage(url, 'PNG', 0, 0, width - 10, height - 10);
                    pdf.addPage();
                } else {
                    pdf.deletePage(pdf.internal.getNumberOfPages());
                    pdf.addPage()
                    width = pdf.internal.pageSize.getWidth();
                    let hratio = img.height / img.width;
                    let imgPDFHeight = width * hratio;
                    pdf.addImage(url, 'PNG', 5, 5, width, imgPDFHeight);
                    // pdf.deletePage(pdf.internal.getNumberOfPages());
                    // pdf.addPage("a0", "p")
                    // width = pdf.internal.pageSize.getWidth();
                    // height = pdf.internal.pageSize.getHeight();
                    // pdf.addImage(url, 'PNG', 0, 0, width, height);
                    pdf.save('DashboardPage.pdf');
                }
            }
        });
    };

    return (
        <React.Fragment>
            <Header />

            <div className="container-2xl m-0">
                <div className="flex flex-wrap m-0">
                    <SideMenu />
                    <main className="col hmis-dashboard-main mx-2.5 md:m-0">
                        <div className="container-2xl m-0">
                            <div className="flex md:flex-wrap flex-wrap md:mb-1 md:px-1">
                                <div className="flex w-full md:w-3/4">
                                    <h2 className="text-3xl font-bold text-black md:text-2xl lg:text-3xl">MH-HMIS Dashboard <span className="hmis-beta">Beta</span></h2>
                                    <div className="flex pt-1">
                                        <a href={pdfPath} target='_blank'>
                                            <img
                                                src='/assets/icons/info.png'
                                                className='info-icon object-cover h-7'
                                                title='Parameter Details'
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="flex flex-row w-full md:w-1/4 justify-center">
                                    <button type="button" className="download-button my-1 px-4 py-2" onClick={downloadAll}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#1E3A8A" className="inline pb-1" height="20" width="20" viewBox="0 0 512 512">
                                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                        </svg> Download All
                                    </button>
                                    <Link to="/compare">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-md mb-1 ml-3 py-2 px-4 mt-1 rounded-full">Compare</button>
                                    </Link>
                                </div>
                            </div>
                            <FilterForm fyear={fyear}
                                district={district}
                                sub_district={sub_district}
                                month={month}
                                area={area}
                                indicator={indicator}
                                areaType={areaType}
                                indicatorCategory={indicatorCategory}
                                districtDropdown={districtDropdown}
                                subdtDropdown={subdtDropdown}
                                fyDropdown={fyDropdown}
                                monthDropdown={monthDropdown}
                                setAreaType={setAreaType}
                                setArea={setArea}
                                setMonth={setMonth}
                                setFyear={setFyear}
                                setDistrict={setDistrict}
                                setSubDt={setSubDt}
                                setbarChartData={setbarChartData}
                                setLineChartData={setLineChartData}
                                setTableData={setTableData}
                                setQualityData={setQualityData}
                                isSelected={isSelected}
                                setIsSelected={setIsSelected}
                                setDataDownloadedDate={setDataDownloadedDate}
                                setBestWorstPerformingBlock={setBestWorstPerformingBlock}
                            />
                            <span>{(isSelected) ? <div className='dowloaded-date text-xs'><i>Data Downloaded Date: <b>{moment(DataDownloadedDate).format('MMMM Do YYYY')}</b></i></div> : <SkeletonDateDiv />}</span>
                            {(isSelected) && (area < 441) ? (
                                <div className="flex flex-col-reverse flex-wrap mb-2 md:my-0 md:flex-row md:px-1 items-center">
                                    <div className='flex w-full lg:w-1/5 justify-center'>
                                        <div className='w-11/12'>
                                            {ToggleButtonSwitch}
                                        </div>
                                    </div>

                                    <div className='flex w-full lg:w-2/5'>
                                        <ReactSlider
                                            className="horizontal-slider max-w-xs md:max-w-lg flex items-center justify-center"
                                            thumbClassName="example-thumb"
                                            trackClassName="example-track"
                                            defaultValue={[25.0, 50.0]}
                                            ariaLabel={["Leftmost thumb", "Rightmost thumb"]}
                                            min={0}
                                            max={100}
                                            onChange={displayValues}
                                            step={0.1}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            pearling
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <div className='flex w-full lg:w-2/5'>
                                        <div className='flex w-1/2 items-center justify-center'>
                                            {isColorCodedTable && <Popup
                                                content={<>
                                                    <TableHolder
                                                        tableData={tableData}
                                                        indicatorCategory={indicatorCategory}
                                                    />
                                                </>}
                                                handleClose={toggleColorTablePopup}
                                            />}
                                            <a onClick={toggleColorTablePopup}>
                                                <button className="text-black font-bold text-md py-2 md:px-3 border-1 rounded-lg border-black hover:bg-blue-100 hover:underline">
                                                    <TableChartIcon className='pb-1' style={{ fontSize: '1.875rem' }} />
                                                    Color Coded Table
                                                </button>
                                            </a>
                                        </div>

                                        <div className='flex w-1/2 items-center justify-center'>
                                            {isOpenTable && <Popup
                                                content={<>
                                                    <DataQualityHolder
                                                        qualityData={qualityData}
                                                        area={area} />
                                                </>}
                                                handleClose={toggleTablePopup}
                                            />}
                                            <a onClick={toggleTablePopup}>
                                                <button className="text-black font-bold text-md py-2 px-3 border-1 rounded-lg border-black hover:bg-blue-100 hover:underline">
                                                    <TableChartIcon className='pb-1' style={{ fontSize: '1.875rem' }} /> Data Quality
                                                </button>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            ) :
                                (
                                    <div className="flex my-2 md:my-0 md:px-1 items-center">
                                        <div className='flex w-1/4 justify-center'>
                                            {ToggleButtonSwitch}
                                        </div>
                                    </div>
                                )}

                            {(isSelected) ? ((barChartData !== null || lineChartData !== null) ?
                                <section id='main_dashboard_container' className='flex flex-col' >
                                    <section
                                        id='top_dashboard_row'
                                        className='flex md:flex flex-wrap'
                                    >
                                        <div className='flex w-full md:w-1/2'>
                                            {(barChartData !== null) ?
                                                <MapHolder barChartData={barChartData}
                                                    areaType={areaType}
                                                    area={area}
                                                    district={district}
                                                    sub_district={sub_district}
                                                    indicatorType={indicatorType}
                                                    indicatorVariant={indicatorVariant}
                                                    areaDetails={areaDetails}
                                                    minVal={min}
                                                    maxVal={max}
                                                    toggleValue={toggleValue}
                                                /> : null}
                                        </div>

                                        <div className='flex w-full md:w-1/2'>
                                            {(barChartData !== null) ?
                                                <BarChartHolder
                                                    barChartData={barChartData}
                                                    indicatorType={indicatorType}
                                                    toggleValue={toggleValue}
                                                    indicatorCategory={indicatorCategory}
                                                    indicatorVariant={indicatorVariant}
                                                    minVal={min}
                                                    maxVal={max}
                                                /> : null}
                                        </div>
                                    </section>

                                    <section
                                        id='bottom_dashboard_row'
                                        className='flex flex-wrap pt-3'
                                    >
                                        <div className='flex w-full md:w-1/2'>
                                            {(lineChartData !== null) ?
                                                <LineChartHolder
                                                    lineChartData={lineChartData}
                                                    toggleValue={toggleValue}
                                                    indicatorCategory={indicatorCategory}
                                                    indicatorVariant={indicatorVariant}
                                                /> : null}
                                        </div>
                                        <div className='flex w-full md:w-1/2'>
                                            {(barChartData !== null && indicatorVariant != 'Percent') ?
                                                <PieChartHolder
                                                    barChartData={barChartData}
                                                    indicatorType={indicatorType}
                                                /> : null}
                                        </div>
                                    </section>
                                    <section
                                        id='blocks_table_row'
                                        className='flex flex-wrap pt-3'
                                    >
                                        <div className='flex w-full'>
                                            {(bestWorstPerformingBlock[0].bestPerforming !== null && indicatorVariant != 'Count') ?
                                                <BlockPerformance
                                                    bestWorstPerformingBlock={bestWorstPerformingBlock}
                                                    toggleValue={toggleValue}
                                                    indicatorCategory={indicatorCategory}
                                                    indicatorVariant={indicatorVariant}
                                                /> : null}
                                        </div>

                                    </section>
                                </section> : <div className='flex text-xl font-bold text-black'>No Data Available</div>) : <SkeletonQuadrant />}
                        </div>
                        <>
                            <Footer />
                        </>
                    </main>
                </div>
            </div >
            {/* </div > */}
        </React.Fragment >
    )

}